import produce from 'immer';
import { ADD_INGREDIENTS, CLEAR_INGREDIENTS } from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id: 0,
    name: '',
    description:"",
    image:"",
    status:'active',
  },
};

const addZoneReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_INGREDIENTS:
        draft.formValues = action.datas;
        break;
      case CLEAR_INGREDIENTS:
        draft.formValues = {};
        break;
      default:
        break;
    }
  });

export default addZoneReducer;
