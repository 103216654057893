import React from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from '../Pages/Standalone/NotFoundDedicated';
import Auth from './Auth';
import Application from './Application';
import LoginDedicated from '../Pages/Standalone/LoginDedicated';
import ThemeWrapper, { ThemeContext } from './ThemeWrapper';
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

function App(props) {
  const { history } = props;
  return (
    <ThemeWrapper>
    <ThemeContext.Consumer>
      {(changeMode) => (
        <Switch>
          <Route path="/" exact component={LoginDedicated} />
          <Route
            path="/"
            render={(props) => {
              if (localStorage.getItem('token') !== '' && localStorage.getItem('token') !== null) {
                return <Application {...props} changeMode={changeMode} />;
              }
              return (
                <Redirect
                  to={{
                    pathname: '/',
                    state: {
                      from: props.location
                    }
                  }}
                />
              );
            }
            }
          />
          <Route component={Auth} />
          <Route component={NotFound} />

        </Switch>
      )}
    </ThemeContext.Consumer>
    </ThemeWrapper>
  );
}

App.propTypes = {
  history: PropTypes.object.isRequired,
};

export default App;
