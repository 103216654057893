import produce from 'immer';
import { ADD_ZONES, CLEAR_ZONES } from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id: 0,
    name: '',
    status:'active',    
  },
};

const addZoneReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_ZONES:
        draft.formValues = action.datas;
        break;
      case CLEAR_ZONES:
        draft.formValues = {};
        break;
      default:
        break;
    }
  });

export default addZoneReducer;
