import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { LoginForm } from 'dan-components';
import styles from 'dan-components/Forms/user-jss';
import Axios from 'axios';
import Qs from 'query-string';
import '../../../config/custom.css';
import { LOGIN_URL, TOKEN_HEADERS } from '../../../config/applicationConstant';

function Login(props) {
  const [valueForm, setValueForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const submitForm = (values) => {
    setTimeout(() => {
      setValueForm(values);
      setErrorMessage('');
      setLoading(true);
      let requestBody = JSON.stringify(values, null, 2);
      let login_data = JSON.parse(requestBody);
      let requestBodydata = {
        email: login_data.email,
        password: login_data.password,
      };
      Axios.post(LOGIN_URL, Qs.stringify(requestBodydata), TOKEN_HEADERS)
        .then((result) => {
          console.log('result');
          if (result.data.error == 0) {
            let user_name =
              result.data.admin.first_name + ' ' + result.data.admin.last_name;
            localStorage.setItem('token', result.data.request_token);
            localStorage.setItem('user_name', user_name);
            window.location.href = '/dashboard';
          } else if (result.data.error == 1) {
            setErrorMessage(result.data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            setErrorMessage(error.response.data.message);
            setLoading(false);
          }
        });
    }, 500); // simulate server latency
  };

  const title = brand.name + ' - Login';
  const description = brand.desc;
  const { classes } = props;
  const custom_state = {
    loading: loading,
    errorMessage: errorMessage,
  };
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='twitter:title' content={title} />
        <meta property='twitter:description' content={description} />
      </Helmet>
      <div className={classes.container}>
        <div className={classes.userFormWrap}>
          <LoginForm
            onSubmit={(values) => submitForm(values)}
            {...custom_state}
          />
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
