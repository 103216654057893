import produce from 'immer';
import { FETCH_MODIFIERDATA, CLEAR_MODIFIERDATA} from '../constants/reduxFormConstants';

const initialState = {
  formValues:{
    name:"",
    label:"",
    price:"",
    display_order:"1",
    product_id:"",
    maximum:"",
    minimum:0
  },

};
const modifierReducer = (state = initialState, action = {}) => produce(state, draft => {
    switch (action.type) {
        case FETCH_MODIFIERDATA:
            draft.formValues = action.data;
            break;
        case CLEAR_MODIFIERDATA:
            draft.formValues = {};
            break;
        default:
        break;
    }
});
export default modifierReducer;
