import produce from 'immer';
import { ADD_PROMO, CLEAR_PROMO } from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id: 0,
    name: '',
    display_order: '',
    display_for: 'all',
    display_type: '',
    status: '',
    places: '',
    banner: [],
  },
};

const addPromoReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_PROMO:
        draft.formValues = action.datas;
        break;
      case CLEAR_PROMO:
        draft.formValues = {};
        break;
      default:
        break;
    }
  });
export default addPromoReducer;
