import produce from 'immer';
import { ADD_COUPON, CLEAR_COUPON } from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id: 0,
    code: '',
    name: '',
    start: '',
    end: '',
    coupon_type: '',
    discount_type: '',
    value: null,
    minimum_order_total: null,
    max_redemptions: null,
    max_uses_per_customer: null,
    first_order: false,
    customer_group: '',
    products:[],
    user_type:[]
  },
};

const addCouponReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_COUPON:
        draft.formValues = action.datas;
        break;
      case CLEAR_COUPON:
        draft.formValues = {
            user_type:[]
        };
        break;
      default:
        break;
    }
  });

export default addCouponReducer;
