// Redux Form
export const INIT = 'INIT';
export const CLEAR = 'CLEAR';

// modifiers Form
export const FETCH_MODIFIERDATA_SUCCESS = 'FETCH_MODIFIERDATA_SUCCESS';
export const FETCH_MODIFIERDATA = 'FETCH_MODIFIERDATA';
export const FETCH_MODIFIERDATA_FAIL = 'FETCH_MODIFIERDATA_FAIL';
export const CLEAR_MODIFIERDATA = 'CLEAR_MODIFIERDATA';
export const FETCH_MODIFIERDATA_TYPE = 'FETCH_MODIFIERDATA_TYPE';
export const EDIT_MODIFIERDATA = 'EDIT_MODIFIERDATA';

export const CLEAR_STORE_DATA = 'CLEAR_STORE_DATA';
export const ADD_STORESDATA = 'ADD_STORESDATA';
export const ADD_STORESDATA_SUCCESS = 'ADD_STORESDATA_SUCCESS';
export const ADD_STORESDATA_FAIL = 'ADD_STORESDATA_FAIL';
export const CLEAR_STORESDATA = 'CLEAR_STORESDATA';
export const EDIT_STORESDATA = 'EDIT_STORESDATA';

export const ADD_BRAND = 'ADD_BRAND';
export const CLEAR_BRAND = 'CLEAR_BRAND';

export const ADD_ATTTRIBUTES = 'ADD_ATTTRIBUTES';
export const CLEAR_ATTTRIBUTES = 'CLEAR_ATTTRIBUTES';

export const USERS_EDIT_LIST = 'USERS_EDIT_LIST';
export const CLEAR_USERS_LIST = 'CLEAR_USERS_LIST';

export const ADD_DEPARTMENTS = 'ADD_DEPARTMENTS';
export const CLEAR_DEPARTMENTS = 'CLEAR_DEPARTMENTS';

export const ADD_PRETIME = 'ADD_PRETIME';
export const CLEAR_PRETIME = 'CLEAR_PRETIME';

export const ADD_DAYPARTS_FORM = 'ADD_DAYPARTS_FORM';
export const CLEAR_DAYPARTS_FORM = 'CLEAR_DAYPARTS_FORM';

// Main category
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';

// Main category
export const CLEAR_VIRTUAL_CATEGORY = 'CLEAR_VIRTUAL_CATEGORY';
export const ADD_VIRTUAL_CATEGORY = 'ADD_VIRTUAL_CATEGORY';

// SUB Category
export const FETCH_SUB_CATEGORY_SUCCESS = 'FETCH_SUB_CATEGORY_SUCCESS';
export const FETCH_SUB_CATEGORY = 'FETCH_SUB_CATEGORY';
export const FETCH_SUB_CATEGORY_FAIL = 'FETCH_SUB_CATEGORY_FAIL';

export const FETCH_ITEM_DATA_SUCCESS = 'FETCH_ITEM_DATA_SUCCESS';
export const FETCH_ITEM_DATA = 'FETCH_ITEM_DATA';
export const FETCH_ITEM_DATA_FAIL = 'FETCH_ITEM_DATA_FAIL';
export const CLEAR_ITEM = 'CLEAR_ITEM';
export const FETCH_ITEM_DATA_EDIT = 'FETCH_ITEM_DATA_EDIT';
export const ADD_ITEM_DATA = 'ADD_ITEM_DATA';
export const UPDATE_SUB_CATEGORY_VALUE = 'UPDATE_SUB_CATEGORY_VALUE';

export const FETCH_MODIFIERSETDATA = 'FETCH_MODIFIERSETDATA';
export const CLEAR_MODIFIERSETSDATA = 'CLEAR_MODIFIERSETSDATA';

export const FETCH_MODIFIERITEMDATA = 'FETCH_MODIFIERITEMDATA';
export const CLEAR_MODIFIERITEMDATA = 'CLEAR_MODIFIERITEMDATA';

export const FETCH_CATEGORY_ITEM_DATA_SUCCESS =
  'FETCH_CATEGORY_ITEM_DATA_SUCCESS';
export const FETCH_CATEGORY_ITEM_DATA = 'FETCH_CATEGORY_ITEM_DATA';
export const FETCH_CATEGORY_ITEM_DATA_FAIL = 'FETCH_CATEGORY_ITEM_DATA_FAIL';

export const ADD_COUPON = 'ADD_COUPON';
export const CLEAR_COUPON = 'CLEAR_COUPON';

export const ADD_ZONES = 'ADD_ZONES';
export const CLEAR_ZONES = 'CLEAR_ZONES';


export const ADD_INGREDIENTS = 'ADD_INGREDIENTS';
export const CLEAR_INGREDIENTS = 'CLEAR_INGREDIENTS';

export const ADD_PROMO = 'ADD_PROMO';
export const CLEAR_PROMO = 'CLEAR_PROMO';

export const ADD_BANNER = 'ADD_BANNER';
export const CLEAR_BANNER = 'CLEAR_BANNER';

export const ADD_CONTACT_US = 'ADD_CONTACT_US';
export const CLEAR_CONTACT_US = 'CLEAR_CONTACT_US';

export const ADD_FAQ = 'ADD_FAQ';
export const CLEAR_FAQ = 'CLEAR_FAQ';

export const ADD_MEMBERSHIP_BENEFITS = 'ADD_MEMBERSHIP_BENEFITS';
export const CLEAR_MEMBERSHIP_BENEFITS = 'CLEAR_MEMBERSHIP_BENEFITS';
