const darkPalette = {
  greyTheme: {
    palette: {
      primary: {
        light: '#ECEFF1',
        main: '#90A4AE',
        dark: '#455A64',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E0E0E0',
        main: '#757575',
        dark: '#424242',
        contrastText: '#fff',
      },
    },
  },
  blueCyanTheme: {
    palette: {
      primary: {
        light: '#E0F7FA',
        main: '#00BCD4',
        dark: '#00838F',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E1F5FE',
        main: '#039BE5',
        dark: '#01579B',
        contrastText: '#fff',
      },
    },
},
goldTheme: {
  palette: {
    primary: {
      light: '#FFF9C4',
      main: '#FF9100',
      dark: '#FF6D00',
      contrastText: '#fff',
    },
    secondary: {
      light: '#EFEBE9',
      main: '#A1887F',
      dark: '#4E342E',
      contrastText: '#fff',
    },
  },
}
};

export default darkPalette;
