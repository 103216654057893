import produce from 'immer';
import { ADD_BANNER, CLEAR_BANNER } from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id: 0,
    link: '',
    link_type: '',
    banner_display_order: '',
    status: '',
    banner_description: '',
    banner_name: '',
    link_text: '',
  },
};

const addPromoReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_BANNER:
        draft.formValues = action.datas;
        break;
      case CLEAR_BANNER:
        draft.formValues = {};
        break;
      default:
        break;
    }
  });
export default addPromoReducer;
