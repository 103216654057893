import produce from 'immer';
import { FETCH_MODIFIERSETDATA, CLEAR_MODIFIERSETSDATA} from '../constants/reduxFormConstants';

const initialState = {
  formValues:{
    name:"",
    label:"",
    display_order:1,
    maximum:"",
    minimum:0,
    modifiers:[]
  },

};
const modifierReducer = (state = initialState, action = {}) => produce(state, draft => {
    switch (action.type) {
        case FETCH_MODIFIERSETDATA:
            draft.formValues = action.data;
            break;
        case CLEAR_MODIFIERSETSDATA:
            draft.formValues = {};
            break;
        default:
        break;
    }
});
export default modifierReducer;
