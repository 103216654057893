import axios from 'axios';

import {
  TOKEN_HEADERS,
  getTokenUpdate,
  ALL_CATEGORY,
  ALL_PRODUCT,
  ALL_SUB_CATEGORY,
  ALL_SUB_CATEGORES_INSIDE,
  ALL_MODIFIER_SETS,
} from '../../config/applicationConstant';

const token = localStorage.getItem('token');

export default class Api {
  static getCategory = (payload) => {
    getTokenUpdate(token);
    const { id } = payload;
    return axios.get(ALL_CATEGORY + '/' + id, TOKEN_HEADERS);
  };

  static getSubCategory = (payload) => {
    getTokenUpdate(token);
    const p_id = payload.id;
    console.log('calling payload', p_id);
    return axios.get(ALL_SUB_CATEGORY + '/' + p_id, TOKEN_HEADERS);
  };

  static getItem = (payload) => {
    getTokenUpdate(token);
    const item_id = payload.id;
    const parent_category_id = payload.parent_id;
    return axios.get(
      ALL_PRODUCT + '/' + item_id + '/' + parent_category_id,
      TOKEN_HEADERS
    );
  };

  static getCategoryItem = (payload) => {
    getTokenUpdate(token);
    const cat_id = payload.id;
    return axios.get(ALL_SUB_CATEGORES_INSIDE + '/' + cat_id, TOKEN_HEADERS);
  };

  static getModiferdata = (payload) => {
    getTokenUpdate(token);
    const modifier_id = payload.id;
    return axios.get(ALL_MODIFIER_SETS + '/' + modifier_id, TOKEN_HEADERS);
  };

  static getStores = (payload) => {
    getTokenUpdate(token);
    const story_id = payload.id;
    return axios.get(ALL_STORES + '/' + story_id, TOKEN_HEADERS);
  };
}
