import produce from 'immer';
import { ADD_STORESDATA, CLEAR_STORE_DATA, EDIT_STORESDATA } from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id:"",
    name:"",
    status:"",
    phone:"",
    longitude:"",
    latitude:"",
    director_email:"",
    store_director:"",
    location_code:"",
    city:"",
    state:"",
    street:"",
    zipcode:"",
    company_id:"",
    working_hours:[],
    time_zone:"",
  }
};


const storeyReducer = (state = initialState, action = {}) => produce(state, draft => {
  switch (action.type) {
    case ADD_STORESDATA:
          draft.formValues = action.data;
          break;
      case EDIT_STORESDATA:
        draft.formValues = action.datas;
        break;
    case CLEAR_STORE_DATA:
        draft.formValues = {};
        break;
    default:
        break;
  }
});
export default storeyReducer;
