import produce from 'immer';
import {
  ADD_ITEM_DATA,
  FETCH_ITEM_DATA_SUCCESS,
  CLEAR_ITEM,
  FETCH_ITEM_DATA,
  FETCH_CATEGORY_ITEM_DATA_SUCCESS,
  FETCH_ITEM_DATA_EDIT,
} from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id: '',
    name: '',
    price: '',
    availability: '',
    description: '',
    priority: '',
    categories: '',
    sub_categories: '',
    modifiers: '',
    image: '',
    allergens: '',
    nutrition_info: '',
    timings: [],
    similar_items: [],
    marketing: {
      meta_title: '',
      meta_description: '',
      meta_keyword: '',
    },
  },
  category_list: [],
  modifiers_list: [],
  stores_list: [],
};

const itemReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_ITEM_DATA:
        draft.formValues = action.datas;
        draft.modifiers_list = [];
        draft.stores_list = [];
        break;
      case FETCH_ITEM_DATA_SUCCESS:
        draft.formValues = action.data;
        draft.modifiers_list = action.data.modifiers;
        draft.stores_list = action.data.stores;
        break;
      case FETCH_CATEGORY_ITEM_DATA_SUCCESS:
        draft.category_list = action.data;
        break;
      case FETCH_ITEM_DATA_EDIT:
        draft.formValues = action.datas;
        break;
      case CLEAR_ITEM:
        draft.formValues = {};
        draft.modifiers_list = [];
        draft.stores_list = [];
        break;
      default:
        break;
    }
  });

export default itemReducer;
