import { all, fork } from 'redux-saga/effects';
import * as fetchitem from './itemSaga';

function* rootSaga() {
  yield all([

      fork(fetchitem.fetchitemSaga),

      fork(fetchitem.fetchcategoryitemSaga),

  ]);
};

export default rootSaga;
