import produce from 'immer';
import {
  ADD_CONTACT_US,
  CLEAR_CONTACT_US,
} from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id: 0,
    title: '',
    status: '',
    display_order: '',
    is_store: '',
    image_path: '',
  },
};

const addContactUsReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_CONTACT_US:
        draft.formValues = action.datas;
        break;
      case CLEAR_CONTACT_US:
        draft.formValues = {};
        break;
      default:
        break;
    }
  });

export default addContactUsReducer;
