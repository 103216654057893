import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../Templates/Dashboard';
import { ThemeContext } from './ThemeWrapper';
import {
  Parent,
  DashboardPage,
  HomePage,
  BlankPage,
  Form,
  Table,
  Error,
  NotFound,
  UserPage,
  Modifiers,
  Store,
  Product,
  Brand,
  Category,
  SubCategory,
  DaypartList,
  Attributes,
  Department,
  OrderPage,
  Pretime,
  Coupon,
  Zone,
  UploadUsers,
  FAQs,
  ContactUs,
  MembershipBenefits,
  DeleteUsers,
  ImportItems,
  ImportSimilarItems,
  ProductMarketingFields,
  Ingredients
} from '../pageListAsync';

function Application(props) {
  const { history } = props;
  const changeMode = useContext(ThemeContext);
  return (
    <Dashboard history={history} changeMode={changeMode}>
      <Switch>
        <Route exact path='/app' component={BlankPage} />
        <Route exact path='/app/blank-page' component={BlankPage} />
        <Route path='/dashboard' component={DashboardPage} />
        <Route path='/homepage' component={HomePage} />
        <Route path='/users' component={UserPage} />
        <Route path='/modifiers' component={Modifiers} />
        <Route path='/stores' component={Store} />
        <Route path='/daypart' component={DaypartList} />
        <Route path='/orders' component={OrderPage} />
        <Route path='/items' component={Product} />
        <Route path='/brands' component={Brand} />
        <Route path='/attributes' component={Attributes} />
        <Route path='/departments' component={Department} />
        <Route path='/coupons' component={Coupon} />
        <Route path='/zones' component={Zone} />
        <Route path='/categories' component={Category} />
        <Route path='/prep-time' component={Pretime} />
        <Route path='/upload' component={UploadUsers} />
        <Route path='/contact' component={ContactUs} />
        <Route path='/faqs' component={FAQs} />
        <Route path='/delete' component={DeleteUsers} />
        <Route path='/import_items' component={ImportItems} />
        <Route path='/similar_items' component={ImportSimilarItems} />
        <Route path='/marketing_fields' component={ProductMarketingFields} />
        <Route path='/membership_benefits' component={MembershipBenefits} />
        <Route path='/sub-categories/:id' component={SubCategory} />
        <Route path ='/ingredients' component={Ingredients} />
        <Route path='/app/pages/form' component={Form} />
        <Route path='/app/pages/table' component={Table} />
        <Route path='/app/pages/not-found' component={NotFound} />
        <Route path='/app/pages/error' component={Error} />
        <Route exact path='/app/pages' component={Parent} />
        <Route component={NotFound} />
      </Switch>
    </Dashboard>
  );
}

Application.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Application;
