/* eslint-disable */

import React from 'react';
import Loading from 'dan-components/Loading';
import loadable from '../utils/loadable';

export const BlankPage = loadable(() => import('./Pages/BlankPage'), {
  fallback: <Loading />,
});
export const DashboardPage = loadable(() => import('./Pages/Dashboard'), {
  fallback: <Loading />,
});
export const HomePage = loadable(() => import('./Pages/HomePage'), {
  fallback: <Loading />,
});
export const Form = loadable(() => import('./Pages/Forms/ReduxForm'), {
  fallback: <Loading />,
});
export const Table = loadable(() => import('./Pages/Table/BasicTable'), {
  fallback: <Loading />,
});
export const Login = loadable(() => import('./Pages/Users/Login'), {
  fallback: <Loading />,
});
export const LoginDedicated = loadable(
  () => import('./Pages/Standalone/LoginDedicated'),
  {
    fallback: <Loading />,
  }
);
export const Register = loadable(() => import('./Pages/Users/Register'), {
  fallback: <Loading />,
});
export const ResetPassword = loadable(
  () => import('./Pages/Users/ResetPassword'),
  {
    fallback: <Loading />,
  }
);
export const NotFound = loadable(() => import('./NotFound/NotFound'), {
  fallback: <Loading />,
});
export const NotFoundDedicated = loadable(
  () => import('./Pages/Standalone/NotFoundDedicated'),
  {
    fallback: <Loading />,
  }
);
export const Error = loadable(() => import('./Pages/Error'), {
  fallback: <Loading />,
});
export const Maintenance = loadable(() => import('./Pages/Maintenance'), {
  fallback: <Loading />,
});
export const ComingSoon = loadable(() => import('./Pages/ComingSoon'), {
  fallback: <Loading />,
});
export const Parent = loadable(() => import('./Parent'), {
  fallback: <Loading />,
});
export const UserPage = loadable(() => import('./Pages/Userlist'), {
  fallback: <Loading />,
});

export const Modifiers = loadable(() => import('./Pages/Modifiers'), {
  fallback: <Loading />,
});

export const Store = loadable(() => import('./Pages/Store'), {
  fallback: <Loading />,
});
export const Product = loadable(() => import('./Pages/Item'), {
  fallback: <Loading />,
});

export const Brand = loadable(() => import('./Pages/Brand'), {
  fallback: <Loading />,
});

export const Attributes = loadable(() => import('./Pages/Attributes'), {
  fallback: <Loading />,
});

export const Category = loadable(() => import('./Pages/Category'), {
  fallback: <Loading />,
});

export const SubCategory = loadable(
  () => import('./Pages/Category/SubCategoryindex'),
  {
    fallback: <Loading />,
  }
);
export const DaypartList = loadable(() => import('./Pages/Daypart'), {
  fallback: <Loading />,
});

export const Department = loadable(() => import('./Pages/Department'), {
  fallback: <Loading />,
});

export const OrderPage = loadable(() => import('./Pages/Order'), {
  fallback: <Loading />,
});

export const Pretime = loadable(() => import('./Pages/PreTime'), {
  fallback: <Loading />,
});

export const Coupon = loadable(() => import('./Pages/Coupons'), {
  fallback: <Loading />,
});

export const Zone = loadable(() => import('./Pages/Zones'), {
  fallback: <Loading />,
});

export const UploadUsers = loadable(() => import('./Pages/UploadUsers'), {
  fallback: <Loading />,
});

export const ContactUs = loadable(() => import('./Pages/ContactUs'), {
  fallback: <Loading />,
});

export const FAQs = loadable(() => import('./Pages/FAQs'), {
  fallback: <Loading />,
});

export const MembershipBenefits = loadable(
  () => import('./Pages/MembershipBenefits'),
  {
    fallback: <Loading />,
  }
);

export const DeleteUsers = loadable(() => import('./Pages/Delete'), {
  fallback: <Loading />,
});

export const ImportItems = loadable(() => import('./Pages/ImportItems'), {
  fallback: <Loading />,
});

export const ImportSimilarItems = loadable(
  () => import('./Pages/ImportSimilarItems'),
  { fallback: <Loading /> }
);

export const Ingredients = loadable(
    () => import('./Pages/Ingredients'),
    {
        fallback: <Loading />
    }
)

export const ProductMarketingFields = loadable(
  () => import('./Pages/ProductMarketingFields'),
  { fallback: <Loading /> }
);
