import produce from 'immer';
import { ADD_VIRTUAL_CATEGORY,CLEAR_VIRTUAL_CATEGORY } from '../constants/reduxFormConstants';


const initialState = {
  formValues: {
    _id:0,
    name: '',
    label:"",
    priority:"",
    status:'',
    products:[],
  }
};

const addVirtualCategoryReducer = (state = initialState, action = {}) => produce(state, draft => {
  switch (action.type) {
    case ADD_VIRTUAL_CATEGORY:
      draft.formValues = action.datas;
      break;
    case CLEAR_VIRTUAL_CATEGORY:
      draft.formValues = {};
      break;
      default:
        break;
  }
});
export default addVirtualCategoryReducer
