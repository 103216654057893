import produce from 'immer';
import { USERS_EDIT_LIST, CLEAR_USERS_LIST } from '../constants/reduxFormConstants';

const initialState = {
  formValues: {}
};

/* eslint-disable default-case, no-param-reassign */
const userFormReducer = (state = initialState, action = {}) => produce(state, draft => {
  switch (action.type) {
    case USERS_EDIT_LIST:
      draft.formValues = action.data;
      break;
    case CLEAR_USERS_LIST:
      draft.formValues = {};
      break;
    default:
      break;
  }
});

export default userFormReducer;
