import produce from 'immer';
import {
  FETCH_MODIFIERITEMDATA,
  CLEAR_MODIFIERITEMDATA,
} from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    name: '',
    label: '',
    price: '',
    display_order: '1',
    product_id: '',
    maximum: '',
    minimum: 0,
    week_day: [],
  },
};
const modifierReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_MODIFIERITEMDATA:
        draft.formValues = action.data;
        break;
      case CLEAR_MODIFIERITEMDATA:
        draft.formValues = {};
        break;
      default:
        break;
    }
  });
export default modifierReducer;
