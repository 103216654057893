module.exports = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    icon: 'ion-ios-home-outline',
    linkParent: '/dashboard',
  },
  {
    key: 'homepage',
    name: 'Home Page',
    icon: 'ion-ios-albums-outline',
    linkParent: '/homepage',
  },
  // {
  //     key: 'customer',
  //     name: 'Customers',
  //     icon: 'ion-ios-people',
  //     linkParent: '/customers',
  // },
  {
    key: 'item',
    name: 'Products',
    icon: 'ion-ios-barcode',
    child: [
      {
        key: 'items_details',
        name: 'Products',
        link: '/items',
        icon: 'ion-ios-attributes',
      },
      {
        key: 'cate_details',
        name: 'Category',
        link: '/categories',
        icon: 'ion-ios-attributes',
      },
      {
        key: 'brand',
        name: 'Brands',
        link: '/brands',
        icon: 'ion-ios-attributes',
      },
      {
        key: 'attributes',
        name: 'Attributes',
        link: '/attributes',
        icon: 'ion-ios-attributes',
      },
      {
        key: 'departments',
        name: 'Department',
        link: '/departments',
        icon: 'ion-ios-attributes',
      },
      {
        key: 'pretimes',
        name: 'Preptime',
        link: '/prep-time',
        icon: 'ion-ios-attributes',
      },
      {
        key: 'coupons',
        name: 'Coupons',
        link: '/coupons',
        icon: 'ion-ios-attributes',
      },
      {
        key: 'zones',
        name: 'Zones',
        link: '/zones',
        icon: 'ion-ios-attributes',
      },
      {
        key: 'ingredient',
        name: 'Ingredients',
        link: '/ingredients',
        icon: 'ion-ios-attributes',
      },
    ],
  },
  {
    key: 'modifiers',
    name: 'Modifiers',
    icon: 'ion-ios-pizza',
    link: '/modifiers',
  },
  // {
  //     key: 'orders',
  //     name: 'All Orders',
  //     icon: 'ion-ios-basket',
  //     linkParent: '/orders',
  // },
  {
    key: 'stores',
    name: 'Stores',
    icon: 'ion-ios-home',
    link: '/stores',
  },
  {
    key: 'no_child',
    name: 'Daypart',
    icon: 'ion-ios-time',
    link: '/daypart',
  },
  {
    key: 'users',
    name: 'Users',
    icon: 'ion-ios-cafe',
    link: '/users',
  },
  {
    key: 'orders',
    name: 'Orders',
    icon: 'ion-ios-basket',
    link: '/orders',
  },
  {
    key: 'utilities',
    name: 'Utilities',
    icon: 'ion-ios-settings',
    child: [
      {
        key: 'upload_users',
        name: 'Upload Users',
        link: '/upload',
      },
      {
        key: 'faqs',
        name: 'FAQs',
        link: '/faqs',
      },
      {
        key: 'contact_us',
        name: 'Contact Us',
        link: '/contact',
      },
      {
        key: 'membership_benefits',
        name: 'Membership Benefits',
        link: '/membership_benefits',
      },
      {
        key: 'delete_account',
        name: 'Delete Account',
        link: '/delete',
      },
      {
        key: 'similar_items',
        name: 'Similar Items',
        link: '/similar_items',
      },
      {
        key: 'marketing_fields',
        name: 'Marketing Fields',
        link: '/marketing_fields',
      },
      {
        key: 'import_items',
        name: 'Import Items',
        link: '/import_items',
      },
    ],
  },
];
