import produce from 'immer';
import {
  ADD_DEPARTMENTS,
  CLEAR_DEPARTMENTS,
} from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id: 0,
    name: '',
    status: 'active',
    categoryId: '',
    created_at: '',
    marketing: {
      meta_title: '',
      meta_description: '',
      meta_keyword: '',
    },
  },
};

const addDepartmentReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_DEPARTMENTS:
        draft.formValues = action.datas;
        break;
      case CLEAR_DEPARTMENTS:
        draft.formValues = {};
        break;
      default:
        break;
    }
  });
export default addDepartmentReducer;
