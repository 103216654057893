import produce from 'immer';
import { ADD_FAQ, CLEAR_FAQ } from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id: 0,
    name: '',
    display_order: '',
    display_for: '',
    status: '',
    items: [],
    membership_id: '',
  },
};

const addFaqReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_FAQ:
        draft.formValues = action.datas;
        break;
      case CLEAR_FAQ:
        draft.formValues = {};
        break;
      default:
        break;
    }
  });

export default addFaqReducer;
