import produce from 'immer';
import { ADD_BRAND,CLEAR_BRAND } from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id:0,
    name: 'adasd',
    status:"active",
    image:"",
    created_at:"",
  }
};

const addBrandReducer = (state = initialState, action = {}) => produce(state, draft => {
  switch (action.type) {
    case ADD_BRAND:
      draft.formValues = action.datas;
      break;
    case CLEAR_BRAND:
      draft.formValues = {};
      break;
      default:
        break;
  }
});
export default addBrandReducer
