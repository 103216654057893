import { call, put, takeEvery,takeLatest } from 'redux-saga/effects';
import {fetchitemdatasuccess,fetchitemdatafail } from 'dan-redux/actions/reduxFormActions';
import Api from '../apis/CategoryApi';
import * as types from '../constants/reduxFormConstants';



export function* fetchitemdatafun(action) {
  //yield put(sendActionstarted(action));
    try {
      const response = yield call(Api.getItem,action);
      if (response) {
        yield put(fetchitemdatasuccess(response.data.product,response.data.product._id));
      }
  } catch (e) {
    yield put(fetchitemdatafail(e.message));
  }
}
export function* fetchitemSaga() {
  yield takeEvery(types.FETCH_ITEM_DATA, fetchitemdatafun);
}


export function* fetchcategoryitemdata(action) {
  //yield put(sendActionstarted(action));
    try {
      const response = yield call(Api.getCategoryItem,action);
      if (response) {
        yield put(fetchcategoryitemsuccess(action.id,response.data.sub_categories));
      }
  } catch (e) {
    yield put(fetchcategoryitemfail(e.message));
  }
}
export function* fetchcategoryitemSaga() {
  yield takeEvery(types.FETCH_CATEGORY_ITEM_DATA, fetchcategoryitemdata);
}
