import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { CSVLink, CSVDownload } from 'react-csv/lib';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  gradientBtn: {
    backgroundColor: '#000000',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 30,
    padding: '10px 10px',
    boxShadow: '0 3px 5px 2px rgba(181, 181, 181, .30)',
    fontSize: '12px',
    letterSpacing: '0.7px',
    textTransform: 'capitalize',
    '& button': {
      backgroundColor: 'transparent',
      color: '#fff',
      marginBottom: '4px',
    },
  },
});

function ExportButton(props) {
  const { classes } = props;

  return (
    <div
      style={{ textAlign: 'right', marginRight: '25px', marginBottom: '25px' }}
    >
      <Tooltip title={props.title}>
        <Typography
          variant='button'
          onClick={props.handleClickproduct}
          style={{ cursor: 'pointer' }}
          classes={{
            root: classNames(classes.gradientBtn, classes.margin), // class name, e.g. `classes-root-x`
            label: classes.label, // class name, e.g. `classes-label-x`
          }}
          className={classes}
        >
          Export
          {props.exportLoading == true && (
            <CircularProgress
              color='#ffffff'
              size={20}
              style={{ marginLeft: '8px', position: 'relative', top: '5px' }}
            />
          )}
        </Typography>
      </Tooltip>
    </div>
  );
}

export default withStyles(styles)(ExportButton);
