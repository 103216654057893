import * as types from '../constants/reduxFormConstants';

export const initAction = (data) => ({ type: types.INIT, data });
export const clearAction = { type: types.CLEAR };

export const fetchmodifierdatasuccess = (data, id) => ({
  type: types.FETCH_MODIFIERDATA_SUCCESS,
  data,
  id,
}); //remove this line

export const editfetchmodifierdata = (datas) => ({
  type: types.EDIT_MODIFIERDATA,
  datas,
}); //remove this line
export const fetchmodifierdatafail = (data) => ({
  type: types.FETCH_MODIFIERDATA_FAIL,
  data: '',
}); //remove this line
export const fetchmodifieroptionstype = (data) => ({
  type: types.FETCH_MODIFIERDATA_TYPE,
  data,
}); //remove this line

export const clearActionmodifier = { type: types.CLEAR_MODIFIERDATA };
export const fetchmodifierdata = (data, id) => ({
  type: types.FETCH_MODIFIERDATA,
  data,
  id,
});

export const fetchmodifiersetdata = (data) => ({
  type: types.FETCH_MODIFIERSETDATA,
  data,
});
export const clearActionmodifierset = { type: types.CLEAR_MODIFIERSETSDATA };

export const fetchmodifieritemdata = (data) => ({
  type: types.FETCH_MODIFIERITEMDATA,
  data,
});
export const clearmodifieritemdata = { type: types.CLEAR_MODIFIERITEMDATA };

export const add_dayparts_action = (data) => ({
  type: types.ADD_DAYPARTS_FORM,
  data,
});
export const clear_dayparts_action = { type: types.CLEAR_DAYPARTS_FORM };

export const edit_users_list = (data) => ({
  type: types.USERS_EDIT_LIST,
  data,
});
export const clear_users_action = { type: types.CLEAR_USERS_LIST };

// ---- for add category form------//

export const clearbrand = { type: types.CLEAR_BRAND };
export const addBrand = (datas) => ({ type: types.ADD_BRAND, datas });

export const clearattributes = { type: types.CLEAR_ATTTRIBUTES };
export const addattributes = (datas) => ({
  type: types.ADD_ATTTRIBUTES,
  datas,
});

export const cleardepartments = { type: types.CLEAR_DEPARTMENTS };
export const adddepartments = (datas) => ({
  type: types.ADD_DEPARTMENTS,
  datas,
});

export const clearMembershipBenefits = {
  type: types.CLEAR_MEMBERSHIP_BENEFITS,
};
export const addMembershipBenefits = (datas) => ({
  type: types.ADD_MEMBERSHIP_BENEFITS,
  datas,
});

export const clearpretimes = { type: types.CLEAR_PRETIME };
export const addpretimes = (datas) => ({ type: types.ADD_PRETIME, datas });

export const clearCoupon = { type: types.CLEAR_COUPON };
export const addCoupon = (datas) => ({ type: types.ADD_COUPON, datas });


export const clearZone = { type: types.CLEAR_ZONES };
export const addZones = (datas) => ({ type: types.ADD_ZONES, datas });


export const clearIngredients = { type: types.CLEAR_INGREDIENTS};
export const addIngredients = (datas) => ({ type: types.ADD_INGREDIENTS, datas });




export const clearContactUs = { type: types.CLEAR_CONTACT_US };
export const addContactUs = (datas) => ({ type: types.ADD_CONTACT_US, datas });

export const clearPromo = { type: types.CLEAR_PROMO };
export const addPromo = (datas) => ({ type: types.ADD_PROMO, datas });

export const clearFaq = { type: types.CLEAR_FAQ };
export const addFaq = (datas) => ({ type: types.ADD_FAQ, datas });

export const clearBanner = { type: types.CLEAR_BANNER };
export const addBanner = (datas) => ({ type: types.ADD_BANNER, datas });

export const clearcategory = { type: types.CLEAR_CATEGORY };
export const addCategory = (datas) => ({ type: types.ADD_CATEGORY, datas });

export const clearvirtualCategory = { type: types.CLEAR_VIRTUAL_CATEGORY };
export const addvirtualCategory = (datas) => ({
  type: types.ADD_VIRTUAL_CATEGORY,
  datas,
});

export const addActionStoryData = (data) => ({
  type: types.ADD_STORESDATA,
  data,
});
export const editActionStoryData = (datas) => ({
  type: types.EDIT_STORESDATA,
  datas,
});
export const clearStoryData = { type: types.CLEAR_STORE_DATA };

export const fetchsubcategory = (data) => ({
  type: types.FETCH_SUB_CATEGORY,
  data,
});
export const fetchsubcategoryfail = (data) => ({
  type: types.FETCH_SUB_CATEGORY_FAIL,
  data: '',
});

export const fetchitemdatasuccess = (data, id, parent_id) => ({
  type: types.FETCH_ITEM_DATA_SUCCESS,
  data,
  id,
  parent_id,
});
export const fetchitemdata = (data, id, parent_id) => ({
  type: types.FETCH_ITEM_DATA,
  data,
  id,
  parent_id,
});
export const fetchitemdataedit = (datas) => ({
  type: types.FETCH_ITEM_DATA_EDIT,
  datas,
});
export const addproductdata = (datas) => ({ type: types.ADD_ITEM_DATA, datas });
export const fetchitemdatafail = (data) => ({
  type: types.FETCH_ITEM_DATA_FAIL,
  data: '',
});

export const clearActionitem = { type: types.CLEAR_ITEM };

export const changeSubCate = (subcate) => ({
  type: types.UPDATE_SUB_CATEGORY_VALUE,
  subcate,
});

export const fetchcategoryitemsuccess = (id, data) => ({
  type: types.FETCH_CATEGORY_ITEM_DATA_SUCCESS,
  id,
  data,
});
export const fetchcategoryitemdata = (id, data) => ({
  type: types.FETCH_CATEGORY_ITEM_DATA,
  id,
  data,
});
export const fetchcategoryitemfail = (data) => ({
  type: types.FETCH_CATEGORY_ITEM_DATA_FAIL,
  data: '',
});
