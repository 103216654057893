import produce from 'immer';
import { ADD_DAYPARTS_FORM, CLEAR_DAYPARTS_FORM} from '../constants/reduxFormConstants';

const initialState = {
  formValues:{
    name:"",
    internal_daypart_id:"",
    status:"active"

  },

};
const daypartsFormReducer = (state = initialState, action = {}) => produce(state, draft => {
    switch (action.type) {
        case ADD_DAYPARTS_FORM:
            draft.formValues = action.data;
            break;
        case CLEAR_DAYPARTS_FORM:
            draft.formValues = {};
            break;
        default:
        break;
    }
});
export default daypartsFormReducer;
