import produce from 'immer';
import { ADD_PRETIME, CLEAR_PRETIME } from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id:0,
    name: '',    
    times:"",
    created_at:"",
  }
};

const addPretimeReducer = (state = initialState, action = {}) => produce(state, draft => {
  switch (action.type) {
    case ADD_PRETIME:
      draft.formValues = action.datas;
      break;
    case CLEAR_PRETIME:
      draft.formValues = {};
      break;
      default:
        break;
  }
});
export default addPretimeReducer
