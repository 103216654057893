import produce from 'immer';
import {
  ADD_MEMBERSHIP_BENEFITS,
  CLEAR_MEMBERSHIP_BENEFITS,
} from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id: 0,
    name: '',
    description: '',
    display_order: '',
    membership_id: null,
    status: 'active',
    image: '',
    display_for: '',
    link: '',
    text: '',
    link_type: '',
    image: '',
  },
};

const addMembershipBenefitsReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_MEMBERSHIP_BENEFITS:
        draft.formValues = action.datas;
        break;
      case CLEAR_MEMBERSHIP_BENEFITS:
        draft.formValues = {};
        break;
      default:
        break;
    }
  });

export default addMembershipBenefitsReducer;
