import produce from 'immer';
import { CLEAR, FETCH_SUB_CATEGORY } from '../constants/reduxFormConstants';

const initialState = {
  formValues: {
    _id: '',
    name: '',
    notes: '',
    parent_category_id: '',
    label: '',
    status: 'active',
    show_home: 'yes',
    category_priority: '',
    created_at: '',
    marketing: {
      meta_title: '',
      meta_description: '',
      meta_keyword: '',
    },
  },
};

const subcategoryReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_SUB_CATEGORY:
        draft.formValues = action.data;
        break;
      case CLEAR:
        draft.formValues = {};
        break;
      default:
        break;
    }
  });
export default subcategoryReducer;
