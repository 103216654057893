import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';

/* Textfield */
export const TextFieldRedux = ({
  meta: { touched, error },
  input,
  ...rest
}) => {
  const [val, setVal] = useState('');
  return (
    <TextField
      {...rest}
      {...input}
      value={val || input.value}
      //onChange={(e) => setVal(e.target.value)}
      error={touched && Boolean(error)}
    />
  );
};

TextFieldRedux.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

TextFieldRedux.defaultProps = {
  meta: null,
};
/* End */

/* MultiTextfield */
export const MultiTextFieldRedux = ({
  meta: { touched, error },
  input,
  ...rest
}) => {
  const [val, setVal] = useState('');
  return (
    <TextField
      {...rest}
      {...input}
      multiline
      minRows={3}
      value={val || input.value}
      error={touched && Boolean(error)}
    />
  );
};

MultiTextFieldRedux.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

MultiTextFieldRedux.defaultProps = {
  meta: null,
};
/* End */

/* Select */
export const SelectRedux = ({ input, children, ...rest }) => (
  <Select {...input} {...rest}>
    {children}
  </Select>
);

SelectRedux.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
/* End */

/* Checkbox */
export const CheckboxRedux = ({ input, ...rest }) => (
  <Checkbox
    checked={input.value === '' ? false : input.value}
    {...input}
    {...rest}
  />
);

CheckboxRedux.propTypes = {
  input: PropTypes.object.isRequired,
};
/* End */

/* Switch */
export const SwitchRedux = ({ input, ...rest }) => (
  <Switch
    checked={input.value === '' ? false : input.value}
    {...input}
    {...rest}
  />
);

SwitchRedux.propTypes = {
  input: PropTypes.object.isRequired,
};
/* End */
